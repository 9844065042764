import { useRouter } from "next/router";
import { useMemo } from "react";

export const useIsB2c = () => {
	const router = useRouter();
	const isB2c = useMemo(() => {
		const isB2cQuery = typeof router.query?.isB2c === "string" ? JSON.parse(router.query?.isB2c!) : "";
		return isB2cQuery;
	}, [router.query?.isB2c]);
	return isB2c;
};

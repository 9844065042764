import Api from "@config/network/ApiConfig";
import { debounce } from '@helpers/assestsFunctions';
import { useGetHotelDropdown } from '@queries/umrah/useHotels';
import { Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";

export default function UmrahHotelSelect({  setSearchKey,setHotels, children, ...props }: any) {
    const [currentPage, setCurrentPage] = useState(0);
    // const [searchKey ,setSearchKey] = useState("");
    const [currTotalCount, setTotalCount] = useState(null);
    const [loading, setLoading] = useState(false);

    // const body = useMemo(() => {
    //     return { pageIndex: currentPage, pageSize: 20, searchKey: searchKey };
    // }, [currentPage, searchKey])

    // const {data: hotels} = useGetHotelDropdown({body})
    useEffect(() => {
        // fetchSearchData();
    }, []);

    // const fetchSearchData = async () => {
    //     if (hotels?.items?.length === 0) {
    //         let body = { pageIndex: currentPage, pageSize: 20, searchKey: '' };
    //         Api.post("UmrahAPI", `/UmraHotels/HotelDropDown`, body)
    //             .then((res) => {
    //                 setTotalCount(res?.responseData?.totalCount);
    //                 let merged: any = [...hotels, ...res?.responseData?.items];
    //                 setHotels(merged);
    //                 setLoading(false);
    //                 setCurrentPage(currentPage + 1);
    //             })
    //             .catch((err) => {
    //                 setLoading(false);
    //             });
    //     }
    // };

    // const handleGetHotel = (e?: any, searchKey?: any) => {
    //     if (searchKey) {
    //         let body = { pageIndex: 0, pageSize: 30, searchKey: searchKey || "" };
    //         Api.post("UmrahAPI", `/UmraHotels/HotelDropDown`, body)
    //             .then((res) => {
    //                 setTotalCount(res?.responseData?.totalCount);
    //                 setHotels(res?.responseData?.items);
    //                 setLoading(false);
    //                 setCurrentPage(0);
    //             })
    //             .catch((err) => {
    //                 setLoading(false);
    //             });
    //         return false;
    //     }
    //     var target = e?.target;
    //     if (!loading && target?.scrollTop + target?.offsetHeight === target?.scrollHeight) {
    //         let pageSize = 20;
    //         if (currTotalCount === null || currentPage * pageSize < currTotalCount) {
    //             setLoading(true);
    //             let body = { pageIndex: currentPage, pageSize, searchKey: "" };
    //             Api.post("UmrahAPI", `/UmraHotels/HotelDropDown`, body)
    //                 .then((res) => {
    //                     setTotalCount(res?.responseData?.totalCount);
    //                     let merged: any = [...hotels, ...res?.responseData?.items];
    //                     setCurrentPage(currentPage + 1);
    //                     setHotels(merged);
    //                     setLoading(false);
    //                 })
    //                 .catch((err) => {
    //                     setLoading(false);
    //                 });
    //         }
    //     }
    // };

    return (
        <Select
            onPopupScroll={(e) => {
                // handleGetHotel(e, false);
            }}
            // onSearch={(val: any) => {
            //     setSearchKey(val)
            //     if (val) {
            //         // handleGetHotel(false, val);
            //     } else {
            //         // handleGetHotel(false, false);
            //     }
            // }}
            onSearch={debounce((val: any) => {
                setSearchKey(val)
            })}
            {...props}
        >
            {children}
        </Select>
    );
}

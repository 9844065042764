import { debounce } from "@helpers/assestsFunctions";
import { useGetAllNationlitiesPagination } from "@queries/masterdata/useMasterdata";
import { Form, FormInstance, FormItemProps, Select, SelectProps } from "antd";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
const { Option } = Select;
const UniqueArray = (arr: any) => arr.filter((v: any, i: any, a: any) => a.findIndex((t: any) => t.id === v.id) === i);
interface NationalityItemProps {
	form: FormInstance;
	keyName?: any;
	placeholder?: string;
	inputProps?: SelectProps;
}
const NationalityItem: React.FC<NationalityItemProps & FormItemProps> = ({ form, keyName = "id", label, placeholder = "", inputProps = {}, ...props }) => {
	// Config
	const { i18n } = useTranslation();

	// State
	const [filterKey, setFilterKey] = useState<any>(null);
	const [pageIndex, setPageIndex] = useState<any>(0);
	const [nationalities, setNationalities] = useState<any>([]);
	const [defaultValue, setDefaultValue] = useState<any>(form.getFieldValue("nationality"));

	// Queries
	const { data: searchedNationality, isLoading: isSearchedLoading } = useGetAllNationlitiesPagination({ lang: i18n?.language, index: pageIndex, key: filterKey });
	const { data: selectedNationality, isLoading: isOneLoading } = useGetAllNationlitiesPagination({ lang: i18n?.language, key: defaultValue });
	const { data: paginationNationalities, isLoading: isAllLoading } = useGetAllNationlitiesPagination({ lang: i18n?.language, index: pageIndex, key: filterKey });

	// Computed
	const nationalitiesIsLoading = isOneLoading || isAllLoading || isSearchedLoading;

	// Watchers
	useEffect(() => {
		setNationalities(
			UniqueArray([
				// Concat all nationalities into one array
				...nationalities,

				// Searched nationality is the first one to be shown
				...(searchedNationality ? searchedNationality : []),

				// Selected nationality is the second one to be shown
				...(selectedNationality ? selectedNationality : []),

				// All nationalities are the last ones to be shown
				...(paginationNationalities || [])
			])
		);
		// If there is no more nationalities to be shown then set pageIndex to null
		if (paginationNationalities === 0) {
			setPageIndex(null);
		}
	}, [searchedNationality, selectedNationality, paginationNationalities]);

	// Methods
	const onSearch = (e: any) => {
		const value = e?.target?.value ?? e?.value ?? e;
		if (value?.trim() || value === "") {
			setPageIndex(0);
			setFilterKey(value.trim());
		}
	};
	const onScroll = (e: { target: any }) => {
		var target = e?.target;
		// When scroll to bottom of the list then load more data
		if (target?.scrollTop + target?.offsetHeight === target?.scrollHeight && !isAllLoading && pageIndex !== null) {
			setPageIndex(pageIndex + 1);
		}
	};

	return (
		<Form.Item {...props}  label={label}>
			<Select
      
				{...inputProps}
				loading={nationalitiesIsLoading}
				showSearch
				allowClear
				size="large"
				filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
				className="input-border-hold search"
				onSearch={debounce((e: any) => {
					onSearch(e), 3000;
				})}
				onPopupScroll={onScroll}
				placeholder={placeholder}
			>
				{nationalities?.map((s: any) => (
					<Option key={s?.id} value={s?.[keyName]}>
						{s?.name}
					</Option>
				))}
			</Select>
		</Form.Item>
	);
};

export default NationalityItem;

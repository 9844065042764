import styled from "@emotion/styled";

const SearchSideStyles = styled.div<{ isGroundService?: boolean; isB2c: boolean }>`
	.search-filter-data-holder {
		width: 100%;
		min-height: ${({ isGroundService }) => (!isGroundService ? "411px" : "min-content")};
		background-color: ${({ theme }: any) => theme.token.Accent};
		border-radius: 8px;
		padding: ${({ isB2c }) => (isB2c ? "45px 17px" : "20px 17px")};
		background-color: ${({ theme }: any) => theme.token.accent};

		.reset-open-filter-holder {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-bottom: 6px;
			&:lang(ar) {
				justify-content: flex-start;
        flex-direction: row-reverse;
			}
			.close-icon-btn {
				color: rgba(235, 42, 20, 0.8);
				font-size: 25px;
				display: none;
			}
			@media (max-width: 992px) {
				margin-bottom: 26px;
				justify-content: space-between;
				.close-icon-btn {
					display: block;
				}
			}
		}
		.reset-form-holder {
			font-size: 12px;
			font-weight: 500;
			color: #8d8d8d;
			text-decoration: underline;
			display: flex;
			justify-content: flex-end;
			cursor: pointer;
			width: fit-content;
			// margin-left: auto;
			margin-bottom: 3px;
		}

		.select-dropdown-holder {
			height: 40px !important;
			display: flex;
			align-items: center;
			background-color: #fff;
			padding: 3px 14px;
			overflow: hidden;
			.select-dropdown--label {
				display: none;
			}
			.select-dropdown--icon {
				margin-right: 10px;
				svg {
					width: 25px;
				}
			}
		}
	}

	.search-aside-content {
		max-width: 290px;
		min-width: 290px;
		width: 100%;
		min-height: 100vh;
		/* & > .filterBy {
			min-height: 173px;
			border-bottom: 1px solid #e3e3e3;
			padding-top: 34px;
			& > span {
				@include font(16px, 600, #542ef9);
				display: block;
			}
			& > p {
				margin: 22px 0px 9px;
				@include font(12px, 500, #8d8d8d);
			}
		} */
		.search-category-name {
			display: flex;
			align-items: center;
			gap: 6px;
			flex: 1;
			font-size: 16px;
			font-weight: 600;
			color: #000000;
			margin-bottom: 16px;
			@media only screen and (max-width: 992px) {
				margin-bottom: 0;
				justify-content: flex-end;
			}
			&:lang(ar) {
				/* justify-content: flex-end; */
				/* flex: initial; */
			}
			svg {
				font-size: 26px;
				color: #949e89;
			}
		}
		& > .packageType {
			min-height: 149px;
			border-bottom: 1px solid #e3e3e3;
			padding-bottom: 16px;
			@media only screen and (max-width: 992px) {
				min-height: initial;
			}
			.checkbox-group-line-holder {
				max-height: 200px;
				overflow: auto;
				flex-wrap: nowrap;
				@media only screen and (max-width: 992px) {
					flex-wrap: wrap;
				}
			}
			& > p {
				margin: 24px 0px 17px;
				font-size: 14px;
				color: #4f4f4f;
			}
		}
		& > .activityLocation {
			min-height: 140px;
			padding-bottom: 50px;
			@media only screen and (max-width: 992px) {
				min-height: initial;
				padding-bottom: 20px;
			}
			& > p {
				margin: 24px 0px 17px;
				font-size: 14px;
				color: #4f4f4f;
			}
			.checkbox-group-line-holder {
				max-height: 200px;
				overflow: auto;
				flex-wrap: nowrap;
				@media only screen and (max-width: 992px) {
					flex-wrap: wrap;
				}
			}
		}
		@media only screen and (max-width: 992px) {
			max-width: 100%;
			min-width: 100%;
			min-height: 100vh;
			position: fixed;
			left: -100%;
			top: 0;
			bottom: 0;
			z-index: 111;
			background-color: #fff;
			padding: 45px 10px;
			transition: all ease-in-out 0.3s;
			overflow: auto;
			&.active {
				left: 0;
				transition: all ease-in-out 0.3s;
			}
		}
	}
	.paxInfo-input {
		position: relative;
	}
	.paxInfo-info {
		width: 100%;
		height: 100%;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		left: 10px;
		z-index: 100;
		font-weight: 500;
		font-size: 14px;
		gap: 5px;
		cursor: pointer;
		&:lang(ar) {
			left: auto;
		}
		p {
			direction: ltr;
		}
		&:lang(ar) {
		}
	}
	.price-range-wrapper {
		margin-top: 15px;
		.price-range-content {
			display: flex;
			gap: 10px;
			.ant-input-number-affix-wrapper {
				width: auto;
				border-radius: 8px;
			}
		}
		.ant-input-number {
			width: auto;
			height: 40px;
			border-radius: 8px;
			.ant-input-number-input {
				/* height: 40px; */
				width: 100%;
				background: #fff;
				/* border: 1px solid #dadde3; */
				border-radius: 8px;
			}
		}
	}
	.ground-service-hotel-class {
		margin-top: 16px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 8px;
		.ground-service-hotel-class-title {
			color: #595959;
			font-weight: 500;
			font-size: 12px;
		}
		.ant-tag {
			cursor: pointer;
		}
	}
`;
export default SearchSideStyles;

import { Tag } from 'antd';
import { useTranslation } from 'next-i18next';
import React from 'react';

interface GroundServiceCategoryTagProps {
	type: 1 | 2 | 3;
	name?: 'VIP' | 'Normal' | 'Premium' | string;
	color?: string;
	className?: string;
	onClick?: () => void;
}

const GroundServiceCategoryTag: React.FC<GroundServiceCategoryTagProps> = ({ type, name, color, className, onClick }) => {
	const { t } = useTranslation();
	const tPrefix = 'umrahJourney.groundService.categories';
	const tagColor = () => {
		switch (type) {
			case 1:
				return '#5FC3CF';
			case 2:
				return '#CFAD5F';
			case 3:
				return '#AA5FCF';
		}
	};
	const textContent = () => {
		switch (type) {
			case 1:
				return t(`${tPrefix}.basic`);
			case 2:
				return t(`${tPrefix}.premium`);
			case 3:
				return t(`${tPrefix}.vip`);
		}
	};
	return (
		<Tag style={{ borderRadius: '31px', minWidth: '42px', padding: '5px 20px' }} className={className} color={color ?? tagColor()} onClick={onClick}>
			{name ?? textContent()}
		</Tag>
	);
};

export default GroundServiceCategoryTag;

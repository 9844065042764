/* eslint-disable jsx-a11y/anchor-is-valid */
import { Pagination } from "antd";
import useIsScreenGreater from "hooks/useIsScreenGreater";
import React from "react";

function OwnPagination({ total, pageSize, current, onChange, ...rest }: any) {
    return (
        <Pagination
            total={total}
            className="pagination-holder"
            current={current || 1}
            defaultCurrent={1}
            pageSize={pageSize || 10}
            hideOnSinglePage
            onChange={onChange}
            simple={!useIsScreenGreater(768)}
            showSizeChanger={false}
            {...rest}
        />
    );
}

export default OwnPagination;

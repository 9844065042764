import { Button, Checkbox, DatePicker, Divider, Form, Input, InputNumber, Select, Slider, Space, Spin } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FlagOutlined, SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useRouter } from "next/router";
import dayjs from "dayjs";
import CounterPopover from "@components/FormInputs/CounterPopover/CounterPopover";
import { useDispatch, useSelector } from "react-redux";
import { maxPriceRange, umrha_location_city } from "@common/constants";
import { useTranslation } from "next-i18next";
import SearchSideStyles from "./Styles";
import { BedIcon, BusIcon, GrayBedIcon, OfferIcon, PromoCodeIcon, StarIcon } from "@common/icons";
import Counter from "@components/FormInputs/Counter/Counter";
import PaxInfoModal from "@components/PaxInfoModal/PaxInfoModal";
import UmrahHotelSelect from "@components/shared/UmrahHotelSelect";
import { useGetHotelDropdown } from "@queries/umrah/useHotels";
import { useGetCountries, useGetDestinations, useGetDestinationsPaginated, useGetRoutes, useGetSearchDestinations, useGetVehicleTypes } from "@queries/masterdata/useMasterdata";
import { useFetchSingleCountryHook } from "hooks/useFetchSingleCountry";
import { debounce } from "@helpers/assestsFunctions";
import NationalityItem from "@components/profile/Items/NationalityItem";
import { useIsB2c } from "@helpers/hooks/useIsB2c";
import { RootState } from "store/rootReducer";
import useCalcAllRooms from "@helpers/hooks/useCalAllRooms";
import { MdOutlinePlace, MdOutlineRoute } from "react-icons/md";
import GroundServiceCategoryTag from "@components/GroundService/GroundServiceCategoryTag";

const { Option, OptGroup } = Select;

let timer: any = null;

function SearchSide({
	isHotel = false,
	hideFilter,
	transportationCategories = false,
	citiesList,
	citiesSelectedList: citiesSelectedListProps,
	isThingsToDo,
	PackageTypes,
	packageDataFilter,
	travellerCount: travellerCountProps,
	isGroundService,
	groundServiceCategories
}: any) {
	const isB2c = useIsB2c();
	const [forceClosePopup, setforceClosePopup] = useState<any>(false);
	const [paxInfoModalVisible, setPaxInfoModalVisible] = useState(false);
	const [roomsValues, setRoomsValues] = useState<any>([
		{
			room_quantity: 1,
			ADT: 1,
			CHD: 0
		}
	]);
	const allRoomAndTraversFieldsValue = useCalcAllRooms(roomsValues);
	const paxInfoInputRef = useRef<HTMLInputElement | null>(null);
	const [form] = Form.useForm();
	const roomFields = Form.useWatch("roomAndTravelers", form);
	const router = useRouter();
	const { t, i18n } = useTranslation("website");
	const isArabicLang = i18n.language === "ar";
	const [travellerCount, setTravellerCount] = useState<number>(1);
	const [priceRange, setPriceRange] = useState<any>([0, 0]);
	const [destinationType, setDestinationType] = useState<any>(null);
	const [destinationsSearch, setDestinationsSearch] = useState<string>("");
	const [selectUmrahDes, setSelectUmrahDes] = useState<any>(null);
	const [passengersCount, setPassengerCount] = useState<number>(1);
	const [vehicleCount, setVehicleCount] = useState<number>(1);
	const [hotelStar, setHotelStar] = useState<any>([]);
	const [VehicleCategory, setVehicleCategory] = useState<any>([]);
	const localCurrency = localStorage?.getItem("UserCurrency") && localStorage?.getItem("UserCurrency") !== "undefined" ? JSON?.parse(localStorage?.getItem("UserCurrency") || "{}") : null;
	const searchType: any = +router?.query?.searchType! || 0;
	const currencyName = localCurrency?.abbreviation;
	const { data: routes } = useGetRoutes({ lang: i18n?.language, isEnable: +searchType === 2 });
	const { data: vechicleTypes } = useGetVehicleTypes({ lang: i18n?.language, isEnable: +searchType === 2 });
	const {} = { lang: i18n?.language };
	const [PackageTypeList, SetPackageTypeList] = useState<any>([]);
	const [citiesSelectedList, SetCitiesSelectedList] = useState<any>(citiesSelectedListProps || []);
	const dispatch = useDispatch();
	const [searchHotelKey, setSearchHotelKey] = useState("");
	const { data: hotels } = useGetHotelDropdown(
		{ searchKey: searchHotelKey, cityCode: selectUmrahDes?.cityCode || router?.query?.cityCode },
		{
			enabled: isHotel
		}
	);
	const { cityCode }: any = router?.query;

	console.log(hotels?.items?.find((el: any) => el?.name === selectUmrahDes?.children)?.providers, selectUmrahDes, "hotels providers");
	const { data: destinations, isFetching: destinationIsLoading, fetchNextPage, refetch: refetchDestionations } = useGetDestinationsPaginated();
	const { data: searchDestinationsItems, isLoading: isSearchDestinationsLoading } = useGetSearchDestinations({ name: destinationsSearch });
	const { data: countries } = useGetCountries({ lang: i18n?.language, enabled: isGroundService });
	const [searchedDestinationItem, setSearchedDestinationsItem] = useState<any>({});
	const { data: fetchedSingleCountry } = useFetchSingleCountryHook({
		countryId: router?.query?.destinationId!,
		destinations,
		name: "",
		keyNameForDestinations: "id",
		onSuccess: (res) => {
			setDestinationType(res?.id);
		}
	});
	useEffect(() => {
		handelSetDataFromRouter();
	}, [router?.asPath]);

	useEffect(() => {
		const { locationCode, type, searchType, cityCode } = router.query;
		if (+searchType! === 1) {
			if (type === "Hotel") {
				setSelectUmrahDes({ key: null, value: "" + locationCode, children: "", type, cityCode });
			} else {
				setSelectUmrahDes(null);
			}
		}
	}, []);

	const handelSetParamsInRouter = (isClearAll?: any, extraQuery?: any) => {
		const values = form?.getFieldsValue();
		const [minPrice, maxPrice] = priceRange;

		if (isGroundService) {
			router.query.nationality = values?.country;
			router.query.country = values?.country;
			router.push(router);
			return;
		}
		let updatedQuery: any = { ...router.query };
		let roomsStr: any = null;
		if (+searchType === 1) {
			const roomFieldsWithChildAges = roomsValues ? roomsValues?.map((room: any) => ({ ...room, age: room?.age?.map((childAge: number | undefined) => childAge || 0) })) : "";
			console.log("roomFieldsWithChildAges", { roomFieldsWithChildAges, roomsValues });
			roomsStr = encodeURIComponent(
				JSON.stringify(
					roomFieldsWithChildAges || [
						{
							room_quantity: 1,
							ADT: 1,
							CHD: 0
						}
					]
				)
			);
		}

		const { startDate, endDate, destinationId, packageName, promoCode, specialCode, routeCode, vehicleTypeCode, cityCode, type, locationCode } = values;
		if (startDate) updatedQuery.startDate = dayjs(startDate).locale("en").format("YYYY-MM-DD");
		else delete updatedQuery["startDate"];
		if (endDate) updatedQuery.endDate = dayjs(endDate).locale("en").format("YYYY-MM-DD");
		else delete updatedQuery["endDate"];
		if (destinationId) updatedQuery.destinationId = destinationId;
		else delete updatedQuery["destinationId"];
		if (minPrice) updatedQuery.minPrice = minPrice;
		else delete updatedQuery["minPrice"];
		if (maxPrice) updatedQuery.maxPrice = maxPrice;
		else delete updatedQuery["maxPrice"];
		if (locationCode) updatedQuery.locationCode = locationCode;
		else delete updatedQuery["locationCode"];
		if (selectUmrahDes) updatedQuery.type = selectUmrahDes?.type || type;
		if (selectUmrahDes && selectUmrahDes?.children)
			updatedQuery.hotelProviders = encodeURIComponent(JSON.stringify(hotels?.items?.find((el: any) => el?.name === selectUmrahDes?.children)?.providers));
		if (selectUmrahDes) updatedQuery.cityCode = selectUmrahDes?.cityCode || cityCode;
		if (destinationType) updatedQuery.destinationType = destinationType;
		else delete updatedQuery["destinationType"];
		if (packageName) updatedQuery.packageName = packageName;
		else delete updatedQuery["packageName"];
		if (promoCode) updatedQuery.promoCode = promoCode;
		else delete updatedQuery["promoCode"];
		if (specialCode) updatedQuery.specialCode = specialCode;
		else delete updatedQuery["specialCode"];
		if (roomsStr) updatedQuery.rooms = roomsStr;
		else delete updatedQuery["rooms"];
		if (routeCode) {
			updatedQuery.routeCode = routeCode;
			updatedQuery.routeName = routes?.find((r: any) => r?.code === routeCode)?.name;
		}
		if (vehicleTypeCode) updatedQuery.vehicleTypeCode = vehicleTypeCode;
		else delete updatedQuery["vehicleTypeCode"];
		if (passengersCount) updatedQuery.noOfPax = "" + passengersCount;
		if (vehicleCount) updatedQuery.quantity = "" + vehicleCount;
		if (travellerCount) updatedQuery.numberOfSeats = "" + travellerCount;
		if (extraQuery) {
			if (extraQuery?.hotelClass) updatedQuery.hotelClass = `${extraQuery?.hotelClass}`;
			else delete updatedQuery["hotelClass"];
			if (extraQuery?.VehicleCategory) updatedQuery.VehicleCategory = `${extraQuery?.VehicleCategory}`;
			else delete updatedQuery["VehicleCategory"];
			if (extraQuery?.packageTypeIDs?.length) updatedQuery.packageTypeIDs = `${extraQuery?.packageTypeIDs}`;
			else delete updatedQuery["packageTypeIDs"];
			if (extraQuery?.CitiesIDs?.length) updatedQuery.CitiesIDs = `${extraQuery?.CitiesIDs}`;
			else delete updatedQuery["CitiesIDs"];
			// if (extraQuery?.minPrice) updatedQuery.minPrice = `${extraQuery?.minPrice}`;
			// else delete updatedQuery["minPrice"];
			// if (extraQuery?.maxPrice) updatedQuery.maxPrice = `${extraQuery?.maxPrice}`;
			// else delete updatedQuery["maxPrice"];
		}
		if (isClearAll) {
			delete updatedQuery["CitiesIDs"];
			delete updatedQuery["isAllPackageType"];
			delete updatedQuery["packageTypeIDs"];
			delete updatedQuery["minPrice"];
			delete updatedQuery["maxPrice"];
			delete updatedQuery["hotelClass"];
			delete updatedQuery["VehicleCategory"];
			delete updatedQuery["promoCode"];
			delete updatedQuery["specialCode"];
			updatedQuery.numberOfSeats = "1";
			SetPackageTypeList([]);
			SetCitiesSelectedList([]);
			setTravellerCount(1);
		}

		setforceClosePopup(true);
		setTimeout(() => {
			setforceClosePopup(false);
		}, 300);
		document.querySelector(".search-aside-content")?.classList.remove("active");
		return updatedQuery;
	};

	const handelSetDataFromRouter = async () => {
		const {
			destinationId,
			minPrice,
			maxPrice,
			startDate,
			endDate,
			packageName,
			packageTypeIDs,
			CitiesIDs,
			numberOfSeats,
			hotelClass,
			VehicleCategory,
			promoCode,
			locationCode,
			specialCode,
			routeCode,
			vehicleTypeCode,
			quantity,
			noOfPax
		}: any = router.query;
		let roomArr = null;
		if (+searchType === 1 && router?.query?.rooms) {
			const roomsStr = decodeURIComponent(typeof router.query?.rooms === "string" ? router.query?.rooms : "");
			roomArr = JSON.parse(roomsStr);
			console.log("roomArr", roomArr);
			setRoomsValues(roomArr);
		}
		form.setFieldsValue({
			// destinationId: destinationId || null,
			packageName,
			specialCode,
			locationCode,
			promoCode,
			minPrice,
			maxPrice,
			routeCode: +routeCode,
			vehicleTypeCode: vehicleTypeCode ? +vehicleTypeCode : 0,
			startDate: startDate ? dayjs(startDate) : null,
			endDate: startDate ? dayjs(endDate) : null,
			roomAndTravelers: roomArr
		});
		// console.log(minPrice, "minPriceminPriceminPriceminPriceminPrice");
		if (minPrice) setPriceRange([minPrice, priceRange[1]]);
		if (maxPrice) setPriceRange([priceRange[0], maxPrice]);
		if (minPrice && maxPrice) setPriceRange([minPrice, maxPrice]);
		if (numberOfSeats) {
			setTravellerCount(+numberOfSeats);
		}
		if (noOfPax) {
			setPassengerCount(+noOfPax || 1);
		}
		if (quantity) {
			setVehicleCount(+quantity || 1);
		}
		if (packageTypeIDs) {
			var selectPackageTypes = packageTypeIDs?.split(",");
			SetPackageTypeList(selectPackageTypes || []);
		}
		if (CitiesIDs) {
			var selectCities = CitiesIDs?.split(",");
			SetCitiesSelectedList(selectCities || []);
		}
		if (hotelClass) {
			var selectHotelClass = hotelClass?.split(",")?.map(Number);
			setHotelStar(selectHotelClass || []);
		}
		if (VehicleCategory) {
			var selectVehicleCategory = VehicleCategory?.split(",")?.map(Number);
			setVehicleCategory(selectVehicleCategory || []);
		}
	};

	// const fetchVechicleTypes = (forceReFetchData = false) => {
	// 	Api.post("UmrahAPI", "/UmraTransportation/GetAllVehicleTypes", {}).then((res) => {
	// 		dispatch(setMasterData({ data: res?.responseData, type: "vechicleTypes" }));
	// 	});
	// };
	// const fetchRoutes = (forceReFetchData = false) => {
	// 	Api.post("UmrahAPI", "/UmraTransportation/GetAllRoutes", {}).then((res) => {
	// 		dispatch(setMasterData({ data: res?.responseData, type: "routes" }));
	// 	});
	// };

	const handelSubmitSearch = (isClearAll?: any, extraQuery?: any) => {
		const updatedQuery = handelSetParamsInRouter(isClearAll || false, extraQuery || false);
		if (isHotel) {
			if (+searchType === 2) {
				router.push({ pathname: router?.pathname, query: updatedQuery }, undefined, { shallow: true, locale: isArabicLang ? "ar" : "en" });
			}
			router.push({ pathname: router?.pathname, query: updatedQuery }, undefined, { shallow: true, locale: isArabicLang ? "ar" : "en" });
		} else {
			router.push({ pathname: router?.pathname, query: updatedQuery }, undefined, { shallow: true, locale: isArabicLang ? "ar" : "en" });
		}
	};

	const handelsetPriceRange = (value: any) => {
		setPriceRange(value);
		const [minPrice, maxPrice] = value || [0, maxPriceRange];
		clearTimeout(timer);
		timer = setTimeout(() => {
			handelSubmitSearch(false, {
				minPrice: minPrice,
				maxPrice: maxPrice,
				packageTypeIDs: PackageTypeList,
				CitiesIDs: citiesSelectedList
			});
		}, 1000);
	};

	const onFormlistModalSaveHandler = () => {
		setPaxInfoModalVisible(false);
		console.log(form.getFieldValue("roomAndTravelers"), "roomAndTravelers");
		setRoomsValues(form.getFieldValue("roomAndTravelers"));
		if (paxInfoInputRef.current) {
			paxInfoInputRef.current?.blur()!;
		}
	};

	const handelCheckPackge = (values: any) => {
		SetPackageTypeList(values || []);
		handelSubmitSearch(false, { packageTypeIDs: values, CitiesIDs: citiesSelectedList });
	};

	const handelCheckCities = (values: any) => {
		SetCitiesSelectedList(values || []);
		handelSubmitSearch(false, { CitiesIDs: values, packageTypeIDs: PackageTypeList });
	};
	const onStartDateHandler = () => {
		const startDate = dayjs(form.getFieldValue("startDate"));
		const endDate = dayjs(form.getFieldValue("endDate"));
		if (startDate.isAfter(endDate)) {
			form.resetFields(["endDate"]);
		}
	};

	const handelSelectUmrahDes = (selected_option: any) => {
		setSelectUmrahDes(selected_option);
	};
	useEffect(() => {
		refetchDestionations();
	}, [router?.locale]);
	useEffect(() => {
		if (!destinationIsLoading) {
			form.setFieldValue("destinationId", router.query?.destinationId);
		}
	}, [destinationIsLoading]);

	const umrahJourneyInfo = useSelector((state: RootState) => state?.umrahJourney?.umrahJourneyInfo);
	useEffect(() => {
		form.setFieldValue("nationality", umrahJourneyInfo?.nationality);
		form.setFieldValue("country", umrahJourneyInfo?.country?.iso2);
	}, [umrahJourneyInfo]);
	return (
		<SearchSideStyles isGroundService={isGroundService}>
			<aside className="search-aside">
				<Form
					size="large"
					layout="vertical"
					form={form}
					name="search-form"
					className="search-form-holder"
					onFinish={() => handelSubmitSearch(false, false)}
					initialValues={{
						roomAndTravelers: [{ room_quantity: 1, ADT: 1, CHD: 0 }]
					}}
				>
					<div className="search-aside-content">
						<div className="Functions_Box search-filter-data-holder">
							<div className="reset-open-filter-holder">
								<CloseCircleOutlined
									className="close-icon-btn"
									onClick={() => {
										document.querySelector(".search-aside-content")?.classList.remove("active");
										setforceClosePopup(true);
										setTimeout(() => {
											setforceClosePopup(false);
										}, 300);
									}}
								/>
								{+searchType === 2 && (
									<p className="search-category-name">
										{" "}
										<MdOutlinePlace /> {t("profile.package.Transportation")}
									</p>
								)}
								{cityCode?.toUpperCase() === "SA1" && (
									<p className="search-category-name">
										<MdOutlinePlace /> {t("profile.package.Makkah")}
									</p>
								)}
								{cityCode?.toUpperCase() === "SA4" && (
									<p className="search-category-name">
										<MdOutlinePlace /> {t("profile.package.Madinah")}
									</p>
								)}
								{!isHotel && +searchType !== 2 && (
									<div
										className="reset-form-holder"
										onClick={() => {
											if (isHotel) {
												form.resetFields(["specialCode", "hotelCodes", "promoCode"]);
											} else {
												form.resetFields();
											}
											handelSubmitSearch(true, false);
										}}
									>
										{t("profile.package.clearAll")}
									</div>
								)}
							</div>
							{isGroundService && (
								<>
									<Form.Item label={isThingsToDo ? t("profile.thingsToDo.activityName") : t("profile.package.Packagename")} name="packageName">
										<Input
											allowClear
											prefix={<SearchOutlined className="site-form-item-icon" />}
											placeholder={isThingsToDo ? t("profile.thingsToDo.activityName") : t("profile.package.Packagename")}
										/>
									</Form.Item>
									<NationalityItem
										inputProps={{ disabled: true }}
										name={"nationality"}
										placeholder={t("forms.fields.placeholders.whatsYourNationality")}
										label={t("forms.fields.placeholders.whatsYourNationality")}
										form={form}
									/>
									<Form.Item
										className={"input-holder "}
										label={t("forms.fields.placeholders.whatsYourCountry")}
										name="country"
										rules={[
											{
												required: true
											}
										]}
									>
										<Select disabled={true} size="large" placeholder={t("forms.fields.placeholders.whatsYourCountry")} className="input-border-hold search">
											{countries &&
												countries?.map(({ name, iso2 }: any) => (
													<Option key={iso2} value={iso2}>
														{name}
													</Option>
												))}
										</Select>
									</Form.Item>
								</>
							)}

							{+searchType === 2 && (
								<>
									<Form.Item label={t("forms.fields.labels.route")} name="routeCode" rules={[{ required: true }]}>
										<Select
											disabled={isB2c}
											suffixIcon={<MdOutlineRoute />}
											showSearch
											placeholder={t("forms.fields.labels.Select a Route")}
											optionFilterProp="children"
											dropdownMatchSelectWidth={true}
											onChange={(val: any) => {}}
										>
											{Array.isArray(routes) &&
												routes?.map((s: any) => (
													<Option type={"Location"} value={s?.code}>
														{s?.name}
													</Option>
												))!}
										</Select>
									</Form.Item>
									<Form.Item label={t("forms.fields.labels.ArrivalDate")} rules={[{ required: true }]} name="startDate">
										<DatePicker disabled={isB2c} disabledDate={(current) => current && current < dayjs().endOf("day")} />
									</Form.Item>
									<Form.Item label={t("sections.transportation.Pax per vehicle")} name="Paxpervehicle">
										<Counter label="" t={t} count={passengersCount} setCount={(value: any) => setPassengerCount(value)} />
									</Form.Item>
									<Form.Item label={t("sections.transportation.Vehicle count")} name="Vehiclecount">
										<Counter icon={<BusIcon />} label="" t={t} count={vehicleCount} setCount={(value: any) => setVehicleCount(value)} />
									</Form.Item>

									<Form.Item label={t("forms.fields.labels.vechicleType")} name="vehicleTypeCode">
										<Select suffixIcon={<BusIcon />} showSearch placeholder={t("forms.fields.placeholders.selectVechicle")} onChange={(val: any) => {}} defaultValue={0} optionFilterProp="children">
											<>
												<Option className="max-capacity-option" type={"Location"} value={0}>
													<b>{t(`profile.personal.all`)}</b>
												</Option>
												{Array.isArray(vechicleTypes) &&
													vechicleTypes?.map((s: any) => (
														<Option className="max-capacity-option" type={"Location"} value={s?.code}>
															<b>{s?.name}</b>
															<div
															// className={classes["max-capacity-label"]}
															>
																<span>{t(`sections.transportation.max capacity`)} : </span> <b>{s?.maxCapacity}</b>
															</div>
														</Option>
													))}
											</>
										</Select>
									</Form.Item>
								</>
							)}
							{!!!searchType && !isGroundService && (
								<>
									<Form.Item label={isThingsToDo ? t("profile.thingsToDo.activityName") : t("profile.package.Packagename")} name="packageName">
										<Input
											allowClear
											prefix={<SearchOutlined className="site-form-item-icon" />}
											placeholder={isThingsToDo ? t("profile.thingsToDo.activityName") : t("profile.package.Packagename")}
										/>
									</Form.Item>
									<Form.Item label={t("forms.fields.labels.Destination")} name={destinations?.length === 0 ? "" : "destinationId"}>
										<Select
											showSearch
											allowClear
											placeholder={t("forms.fields.labels.Destination")}
											optionFilterProp="children"
											suffixIcon={<FlagOutlined />}
											loading={destinationIsLoading}
											onChange={(_, option: any) => {
												if (!destinations?.pages?.some((el) => el?.id === option?.key)) {
													setSearchedDestinationsItem({ name: option?.children, id: option?.key });
												}
												// setSearchedDestinationsItem(option);
												setDestinationType(option?.destinationType || null);
												setDestinationsSearch("");
											}}
											// filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
											filterOption={(input, option) => searchDestinationsItems}
											onSearch={debounce((value: any) => {
												setSearchedDestinationsItem(null);
												setDestinationsSearch(value);
											})}
											value={destinationType}
											onPopupScroll={(e) => {
												var target = e?.target as HTMLDivElement;
												if (!destinationIsLoading && target?.scrollTop + target?.offsetHeight === target?.scrollHeight) {
													fetchNextPage();
												}
											}}
										>
											{/* {fetchedSingleCountry && destinationsSearch === "" && (
												<Option key={fetchedSingleCountry?.id} destinationType={fetchedSingleCountry?.typeID} value={fetchedSingleCountry?.id}>
													{fetchedSingleCountry?.name || fetchedSingleCountry?.countryName}
												</Option>
											)}
											{searchedDestinationItem?.id && destinationsSearch === "" && (
												<Option key={searchedDestinationItem?.id} destinationType={searchedDestinationItem?.typeID} value={searchedDestinationItem?.id}>
													{searchedDestinationItem?.name || searchedDestinationItem?.countryName}
												</Option>
											)}
											{destinationsSearch !== ""
												? searchDestinationsItems?.map((des: any) => (
														<Option typeID={des?.typeID} key={des?.id} value={des?.id}>
															{des?.name || des?.countryName}
														</Option>
												  ))
												: destinations?.pages?.map((res) =>
														res?.responseData?.map((s: any) => (
															<Option key={s?.id} destinationType={s?.typeID} value={s?.id}>
																{s?.name || s?.countryName}
															</Option>
														))
												  )} */}

											{fetchedSingleCountry && destinationsSearch === "" && (
												<Option typeID={fetchedSingleCountry?.typeID} key={fetchedSingleCountry?.id} value={fetchedSingleCountry?.id}>
													{fetchedSingleCountry?.name || fetchedSingleCountry?.countryName}
												</Option>
											)}
											{destinationsSearch !== ""
												? searchDestinationsItems?.map((des: any) => (
														<Option typeID={des?.typeID} key={des?.id} value={des?.id}>
															{des?.name || des?.countryName}
														</Option>
												  ))
												: destinations?.pages?.map((res) =>
														res?.responseData.map((s: any) => (
															<Option typeID={s?.typeID} key={s?.id} value={s?.id}>
																{s?.name || s?.countryName}
															</Option>
														))
												  )}
											{destinationIsLoading && (
												<Option>
													<Spin />
												</Option>
											)}
										</Select>
									</Form.Item>
								</>
							)}
							{+searchType === 1 && (
								<>
									<Form.Item label={t("profile.package.hotel name")} name="locationCode">
										<UmrahHotelSelect
											showSearch
											placeholder={t("profile.package.hotel name")}
											optionFilterProp="children"
											bordered={true}
											suffixIcon={<SearchOutlined />}
											setSearchKey={setSearchHotelKey}
											// disabled={router?.query?.isB2c}
											// setHotels={setHotels}
											// hotels={hotels}
											onChange={(val: any, option: any) => {
												handelSelectUmrahDes(option);
											}}
											value={selectUmrahDes?.value || null}
										>
											<OptGroup label={t("profile.createPackage.fields.common.label.City")}>
												{umrha_location_city?.map((s: any) => (
													<Option disabled={isB2c} type={"Location"} value={s?.locationCode} cityCode={s?.locationCode}>
														{isArabicLang ? s?.city_ar : s?.city}
													</Option>
												))}
											</OptGroup>

											<OptGroup label={t("common.options.hotels")}>
												{hotels?.items?.map((s: any) => (
													<Option type="Hotel" cityCode={s?.cityCode} value={"" + s?.umrahHotelCode}>
														{s?.name}
													</Option>
												))}
											</OptGroup>
										</UmrahHotelSelect>
									</Form.Item>
								</>
							)}
							{(+searchType === 1 || !!!searchType) && !isGroundService && (
								<>
									<Form.Item name="startDate" label={t("profile.package.start")}>
										<DatePicker
											disabled={isB2c}
											onChange={onStartDateHandler}
											disabledDate={(current) => (+searchType === 1 ? current < dayjs().add(4, "days") : current < dayjs().endOf("day"))}
											placeholder={t("profile.package.startDate")}
										/>
									</Form.Item>
									<Form.Item name="endDate" label={t("profile.package.end")}>
										<DatePicker
											disabled={isB2c}
											disabledDate={(current) => current && current < dayjs(form.getFieldValue("startDate") || dayjs().endOf("day")).endOf("day")}
											placeholder={t("profile.package.endDate")}
										/>
									</Form.Item>
								</>
							)}

							{+searchType === 1 && (
								<>
									<Form.Item label={t("forms.fields.labels.roomAndTravelers")} name="paxInfo" className="paxInfo-input">
										<Input prefix={<BedIcon />} />
										<div onClick={() => setPaxInfoModalVisible(true)} className="paxInfo-info">
											<Space align="center" size={5}>
												<p>{allRoomAndTraversFieldsValue?.room_quantity || 1}</p>
												<p>{`${t("forms.fields.labels.rooms")}`} </p>
											</Space>
											<Space align="center" size={5}>
												<p>{allRoomAndTraversFieldsValue?.ADT || 1}</p>
												<p>{`${t("forms.fields.labels.adults")}`} </p>
											</Space>
											,
											<Space align="center" size={5}>
												<p>{allRoomAndTraversFieldsValue?.CHD || 0}</p>
												<p>{`${t("forms.fields.labels.childs")}`} </p>
											</Space>
										</div>
									</Form.Item>
									<PaxInfoModal form={form} visible={paxInfoModalVisible} onFormlistModalSave={onFormlistModalSaveHandler} onCancel={() => setPaxInfoModalVisible(false)} />
									{!router.query.isB2c && (
										<>
											<Form.Item label={t("forms.fields.labels.promoCode")} name="promoCode">
												<Input prefix={<PromoCodeIcon />} placeholder={t("forms.fields.placeholders.promoCodeHere")} />
											</Form.Item>
											<Form.Item label={t("forms.fields.labels.specialCode")} name="specialCode">
												<Input prefix={<OfferIcon />} placeholder={t("forms.fields.placeholders.specialCodeHere")} />
											</Form.Item>
										</>
									)}
								</>
							)}

							{+searchType === 1 && <></>}
							{!!!searchType && !isGroundService && (
								<>
									<CounterPopover t={t} count={travellerCount} setCount={(value: any) => setTravellerCount(value)} />
								</>
							)}

							{searchType !== 2 && (
								<div className="price-range-wrapper">
									{/* <Form.Item label={t("forms.fields.labels.PriceRange")} name="range"> */}
									<div className="price-range-content">
										<Form.Item label={t("common.sentence.from")} name="minPrice">
											<InputNumber
												prefix={<p style={{ fontSize: "12px" }}>{currencyName}</p>}
												// formatter={(value: any) => `${currencyName} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
												// parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")}
												value={+priceRange[0] || null}
												onChange={(val: any) => {
													setPriceRange([val, priceRange[1]]);
												}}
												min={0}
												max={priceRange[1] !== 0 && priceRange[1]}
												// placeholder={t("common.sentence.from")}
											/>
										</Form.Item>
										{/* `${currencyName}` */}
										<Form.Item label={t("profile.createPackage.fields.common.label.To")} name="maxPrice">
											<InputNumber
												prefix={<p style={{ fontSize: "12px" }}>{currencyName}</p>}
												// formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
												// parser={(value: any) => value!.replace(/\$\s?|(,*)/g, '')}
												value={+priceRange[1] || null}
												onChange={(val: any) => {
													setPriceRange([priceRange[0], val]);
												}}
												min={priceRange[0] || 0}
												// placeholder={t("common.sentence.to")}
												// onChange={onChange}
											/>
										</Form.Item>
									</div>
									{/* </Form.Item> */}
								</div>
							)}
							<Button type="primary" htmlType="submit" size="middle" className="login-form-button">
								{t("common.buttons.search")}
							</Button>
						</div>

						{+searchType === 2 && (
							<>
								{/* <Form.Item label={t("umrah.transportation.Vehicle Model")} name="model" className="input-holder">
                                    <Select
                                        placeholder={t("umrah.transportation.Vehicle Model")}
                                    >
                                        <Option key={1} value={1}>
                                            2000 - 2005
                                        </Option>
                                        <Option key={2} value={2}>
                                            2005 - 2010
                                        </Option>
                                        <Option key={3} value={3}>
                                            2010 - 2015
                                        </Option>
                                        <Option key={4} value={4}>
                                            2015 - 2020
                                        </Option>
                                        <Option key={5} value={5}>
                                            2020 - 2023
                                        </Option>
                                    </Select>
                                </Form.Item> */}
								{/* {transportationCategories && (
									<div className="checkbox-group-holder">
										<Form.Item label={t('umrah.transportation.Vehicle Category')} name="vehicleCategoryCode">
											<Checkbox.Group
												value={VehicleCategory || []}
												onChange={(values: any) => {
													const checked_VehicleCategory = values?.length ? values[values?.length - 1] : [];
													setVehicleCategory(values);
													const [minPrice, maxPrice] = priceRange || [0, maxPriceRange];
													console.log(values, 'valueswwww');
													console.log(checked_VehicleCategory, 'checked_VehicleCategory');
													handelSubmitSearch(false, { VehicleCategory: checked_VehicleCategory, minPrice, maxPrice });
												}}
											>
												{transportationCategories?.map((s: any) => (
													<Checkbox key={s?.id} value={s?.id}>
														{s?.name}
													</Checkbox>
												))}
											</Checkbox.Group>
										</Form.Item>
									</div>
								)} */}
							</>
						)}
						{+searchType === 1 && !isGroundService && (
							<>
								<Divider />
								<Form.Item label={t("profile.package.Hotel class")} name="hotelClass" className="input-holder">
									<div className="checkbox-group-holder checkbox-stars-holder">
										<Checkbox.Group
											style={{ width: "100%" }}
											value={hotelStar || []}
											onChange={(values: any) => {
												const checked_star = values?.length ? values[values?.length - 1] : [];
												setHotelStar([checked_star]);
												const [minPrice, maxPrice] = priceRange || [0, maxPriceRange];
												handelSubmitSearch(false, { hotelClass: checked_star, minPrice, maxPrice });
											}}
										>
											<Checkbox value={1}>
												<StarIcon /> 1
											</Checkbox>
											<Checkbox value={2}>
												{" "}
												<StarIcon /> 2
											</Checkbox>
											<Checkbox value={3}>
												{" "}
												<StarIcon /> 3
											</Checkbox>
											<Checkbox value={4}>
												{" "}
												<StarIcon /> 4
											</Checkbox>
											<Checkbox value={5}>
												{" "}
												<StarIcon /> 5
											</Checkbox>
										</Checkbox.Group>
									</div>
								</Form.Item>
							</>
						)}
						{!!!searchType && !isGroundService && (
							<>
								<div className="packageType">
									<p>{isThingsToDo ? t("forms.fields.labels.activitytype") : t("forms.fields.labels.Packagetype")}</p>
									<div className="checkbox-group-line-holder">
										{/* <Form.Item label={""} name="wwwwwwwwwww"> */}
										<Checkbox.Group onChange={handelCheckPackge} value={PackageTypeList}>
											{packageDataFilter?.packageTypes?.map((s: any) => (
												<Checkbox key={s?.id} value={s?.id}>
													{s?.name}
												</Checkbox>
											))}
										</Checkbox.Group>
										{/* </Form.Item> */}
									</div>
								</div>
								<div className="activityLocation">
									<p>{t("profile.package.Activityloaction")}</p>
									<div className="checkbox-group-line-holder">
										<Checkbox.Group onChange={handelCheckCities} value={citiesSelectedList}>
											{citiesList?.map((s: any) => (
												<Checkbox key={s?.id} value={s?.id}>
													{s?.name}
												</Checkbox>
											))}
										</Checkbox.Group>
									</div>
								</div>
							</>
						)}
						{isGroundService && (
							<div className="ground-service-hotel-class">
								<p className="ground-service-hotel-class-title">{t("profile.package.packageClass")}</p>
								<Space>
									{groundServiceCategories?.map((category: any) => (
										<GroundServiceCategoryTag onClick={() => {}} type={category?.id} color={"#4F7624"} />
									))}
									{/* <GroundServiceCategoryTag onClick={() => {}} type={1} color={"#4F7624"} />
									<GroundServiceCategoryTag onClick={() => {}} type={2} color={"#4F7624"} />
									<GroundServiceCategoryTag onClick={() => {}} type={3} color={"#4F7624"} /> */}
								</Space>
							</div>
						)}
					</div>
				</Form>
			</aside>
		</SearchSideStyles>
	);
}

export default SearchSide;

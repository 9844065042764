import { Skeleton, Space } from "antd";
import React from "react";
export default function SkeletonCard({ style }) {
    return (
        <div className="skeleton-card-holder" style={style}>
            <Skeleton.Image active />
            <Skeleton active paragraph={{ rows: 4 }} />
        </div>
    );
}

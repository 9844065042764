import { Form, InputNumber, Popover } from "antd";
import React, { useState } from "react";
import { PlusCircleFilled, MinusCircleFilled, TeamOutlined } from "@ant-design/icons";

interface CounterPopoverProps {
  t: any;
  setCount: (count?: number) => void;
  count: number;
  isBorderInput?: boolean
}

const CounterPopover:React.FC<CounterPopoverProps> = ({ t, setCount, count, isBorderInput = false }) => {
	const content = (
		<div className="count-row-wrapper">
			<h6 className="count-row--title">{t("forms.fields.labels.Seats")}</h6>
			<div className="count-content-holder">
				<InputNumber
					addonBefore={<MinusCircleFilled disabled={count === 1} onClick={() => setCount(count - 1)} />}
					addonAfter={<PlusCircleFilled onClick={() => setCount(count + 1)} />}
					min={1}
					max={100}
					value={count}
					defaultValue={1}
					bordered={false}
					onChange={(value: any) => setCount(value)}
				/>
			</div>
		</div>
	);

	return (
		<Form.Item label={t("forms.fields.labels.Travellers")} name="numberOfSeats" className={isBorderInput ? "input-border-holder" : "input-popover"}>
			<TeamOutlined />
			<Popover placement="bottom" align={{ offset: [0, -14] }} content={content} title={t("forms.fields.labels.Travellers")} trigger="click">
				<p className="popover-content">
					{count} {t("forms.fields.labels.Seats")}
				</p>
			</Popover>
		</Form.Item>
	);
}

export default CounterPopover;
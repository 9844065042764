import { Form, InputNumber } from "antd";
import React from "react";
import { PlusCircleFilled, MinusCircleFilled, TeamOutlined } from "@ant-design/icons";
import { MdDirectionsBusFilled } from "react-icons/md";

interface CounterProps {}

interface CounterProps {
	t: any;
	setCount: (count?: number) => void;
	count: number;
	isBorderInput?: boolean;
	icon?: any;
	label: string;
}

const Counter: React.FC<CounterProps> = ({ t, setCount, count, isBorderInput = false, label, icon }) => {
	const content = (
		<div className="count-row-wrapper">
			<h6 className="count-row--title">{t("forms.fields.labels.Seats")}</h6>
		</div>
	);
	return (
		<Form.Item label={label ?? t("forms.fields.labels.Travellers")} name="numberOfSeats" className={isBorderInput ? "input-border-holder content-between" : "input-popover"}>
			{icon ? icon : <TeamOutlined />}
			<div className="count-row-wrapper">
				<div className="count-content-holder">
					<InputNumber
						addonBefore={<MinusCircleFilled disabled={count === 1} onClick={() => setCount(count - 1)} />}
						addonAfter={<PlusCircleFilled onClick={() => setCount(count + 1)} />}
						min={1}
						max={100000}
						value={count}
						defaultValue={1}
						bordered={false}
						onChange={(value: any) => setCount(value)}
					/>
				</div>
			</div>
		</Form.Item>
	);
};

export default Counter;
